import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { SigninService } from 'src/app/services/api/signin.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends OnDestroyMixin implements OnInit {

  constructor(private router: Router, private formBuilder: FormBuilder,
    public signinService: SigninService, private common: CommonService,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    const paramsAccessToken = this.activatedRoute.snapshot.queryParams['accessToken'];
    const paramsOrgId = this.activatedRoute.snapshot.queryParams['orgId'];
    const paramsStudentId = this.activatedRoute.snapshot.queryParams['studentId'];
    const paramsRoleId = this.activatedRoute.snapshot.queryParams['roleId'];

    let detailsOfLogin: any = { orgId: paramsOrgId, studentId: paramsStudentId, roleId: paramsRoleId, accessToken: paramsAccessToken }
    detailsOfLogin = JSON.stringify(detailsOfLogin);
    sessionStorage.setItem("detailsOfLogin", detailsOfLogin);

    let userType: any = sessionStorage.getItem('detailsOfLogin');
    userType = JSON.parse(userType);
    console.log('userType', userType);
    if (JSON.stringify(userType) != "{}") {
      if (userType.orgId === '10') {
        this.router.navigateByUrl('/layout/higher-ed/organizations/corporations/directed-analysis');
      } else {
        this.router.navigateByUrl('/layout/higher-ed/organizations/community-college/brcc');
      }
    }
  }

}
