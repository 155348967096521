import { Injectable, NgZone } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, Subscription, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from "@angular/router";
import { User } from '../models/user/user';
import { CommonService } from '../services/common.service';
import jwt_decode from "jwt-decode";


@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    interval: any;
    subscription: Subscription
    isLogin = false;

    signObj: User = {};

    constructor(private ngZone: NgZone, public router: Router, public common: CommonService) { }



    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = request.url;

        this.clean(request.body)


        let userType: any = sessionStorage.getItem('menus');
        userType = JSON.parse(userType);
        let detailsOfLogin: any = sessionStorage.getItem('detailsOfLogin');
        detailsOfLogin = JSON.parse(detailsOfLogin);

        let decodeToken: any = jwt_decode(detailsOfLogin.accessToken);
        this.signObj.firstName = decodeToken?.name;
        //     if (!url.endsWith('getAllAssignedByStudentId')) {

        //     request = request.clone({
        //         setHeaders: {
        //             "Access-Control-Allow-Origin": '*',
        //             'Authorization': detailsOfLogin.accessToken,
        //             'createdBy': `${this.signObj.firstName}`
        //         },
        //         body: {
        //             'orgId': detailsOfLogin.orgId,
        //             'studentId': detailsOfLogin.studentId,
        //             'roleId': detailsOfLogin.roleId
        //         }

        //     });

        // }



        const regex = /\.svg$/;
        const isSvg = regex.test(request.url);

        request = request.clone({
            setHeaders: {
                "Access-Control-Allow-Origin": '*',
                'Authorization': detailsOfLogin.accessToken,
                'createdBy': `${this.signObj.firstName}`
            }
        });

        if (url.endsWith('getAllAssignedByStudentId') || url.endsWith('assignRoadmapById') || url.endsWith('createAssignDetails')
            || url.endsWith('updateAssignDetailsById') || url.endsWith('removeAssign') || url.endsWith('getActivityByStudentId')
            || url.endsWith('uploadFile') || url.endsWith('setPermission') || url.endsWith('assignApproleToUser')) {

            request.body.orgId = detailsOfLogin.orgId;
            request.body.studentId = request.body.studentId;
            request.body.roleId = detailsOfLogin.roleId;
        } else if (url.endsWith('updateStudentById') || url.endsWith('createUser') || url.endsWith('updateUser') || url.endsWith('updateStudent')) {
            request.body.orgId = detailsOfLogin.orgId
            request.body.studentId = request.body.studentId;
            request.body.roleId = request.body.roleId;
        } else if (isSvg) {

            request = request.clone({
                setHeaders: {
                    "Access-Control-Allow-Origin": '*',
                    'Authorization': detailsOfLogin.accessToken,
                    'createdBy': `${this.signObj.firstName}`
                }
            });

        }

        else {
            request.body.orgId = detailsOfLogin.orgId;
            request.body.studentId = detailsOfLogin.studentId;
            request.body.roleId = detailsOfLogin.roleId;
            console.log('request.body', request.body);
        }


        return next.handle(request).pipe(
            // We use the map operator to change the data from the response
            map(resp => {
                // Several HTTP events go through that Observable
                // so we make sure that this is a HTTP response
                if (resp instanceof HttpResponse) {
                    // Just like for request, we create a clone of the response
                    // and make changes to it, then return that clone

                    if (resp.body?.code == 408) {
                        this.common.showErrorMessage(resp.body.message);
                        this.common.logout(resp.body);
                        return;
                    }
                    let url = resp.url;
                    if (url) {

                        if (this.isUserLoggedIn()) {
                            this.signObj.firstName = this.signObj.firstName;
                            this.isLogin = true;
                        }
                        this.lastAction(Date.now());
                        this.check();
                        this.initListener();
                        this.initInterval();
                        this.clean(request.body)



                    } else {

                        if (this.interval) {
                            clearInterval(this.interval);
                        }

                        this.interval = setInterval(() => {
                            this.common.showErrorMessage("session time out");
                            this.common.logout(resp.body);

                            clearInterval(this.interval);
                        }, this.common.sessionValidMinutes)
                        this.clean(request.body)

                    }

                    return resp.clone();
                }
            }))

    }

    /**
     * last action
     */
    getLastAction() {
        return sessionStorage.getItem('lastAction');
    }

    /**
     * set last action
     * @param value
     */
    lastAction(value) {
        sessionStorage.setItem('lastAction', JSON.stringify(value))
    }

    /**
     * start event listener
     */
    initListener() {
        this.ngZone.runOutsideAngular(() => {
            document.body.addEventListener('click', () => this.reset());
        });
    }

    /**
     * time interval
     */
    initInterval() {
        this.ngZone.runOutsideAngular(() => {
            setInterval(() => {
                this.check();
            }, 1000);
        })
    }

    /**
     * reset timer
     */
    reset() {
        this.lastAction(Date.now());
    }

    /**
     * check timer
     */

    check() {
        const now = Date.now();
        const timeLeft = parseInt(this.getLastAction()) + (5) * 60 * 10000;
        const diff = timeLeft - now;
        const isTimeout = diff < 0;
        this.ngZone.run(() => {
            if (isTimeout && this.isLogin) {
                setTimeout(() => {
                    this.common.showErrorMessage("Your Session Expired due to longer Inactivity, Login Again To Continue");
                }, 100);
                this.common.logout();
            }
        });
    }

    isUserLoggedIn(): string {
        return '123';
    }



    encryptionOf(msg) {
        var encrptMsg = '';
        var i;
        var j;
        var EN_FROM_KEYS = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "=", "#", "$", "-", "+", "_", ".", "@", "&", "!"];
        var EN_TO_KEYS = ["p", "q", "r", "s", "t", "k", "l", "m", "n", "o", "a", "b", "c", "d", "e", "u", "v", "w", "x", "y", "z", "f", "g", "h", "i", "j", "H", "I", "J", "K", "L", "M", "X", "Y", "Z", "E", "F", "G", "N", "O", "P", "Q", "R", "A", "B", "C", "D", "S", "T", "U", "V", "W", "9", "6", "1", "7", "8", "2", "0", "4", "5", "=", "3", ".", "!", "-", "@", "_", "$", "#", "+", "&"];
        for (i = 0; i < msg.length; i++) {
            var ch = msg.charAt(i);
            for (j = 0; j < EN_FROM_KEYS.length; j++)
                if (ch == EN_FROM_KEYS[j]) {
                    ch = EN_TO_KEYS[j];
                    break;
                }
            encrptMsg += ch;
        }
        encrptMsg = this.makeid() + encrptMsg + this.makeid();
        return encrptMsg;
    }



    makeid() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 5; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
    clean(obj) {
        for (var propName in obj) {

            if (obj[propName] === null || obj[propName] === "" || obj[propName] === undefined || obj[propName] === 0) {
                delete obj[propName];
            }
        }
        return obj
    }



}
